<template>
  <div>
    <loading-screen v-if="isLoading"></loading-screen>
    <v-card v-else>
      <v-card-title>
        Daftar Report Timeline
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="datas"
        :search="search"
        mobile-breakpoint="0"
      >
        <template v-slot:item="row">
          <tr v-if="row.item.reportData">
            <td>{{ row.item.reportId }}</td>
            <td>
              <a target="_blank" :href="`/user/${row.item.user.id}`">{{
                row.item.user.fullName
              }}</a>
            </td>
            <td>
              {{ row.item.reportData.content }}
              <v-btn
                icon
                :href="`${baseWebURL}timeline/${row.item.reportData.feedId}`"
                target="_blank"
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </td>
            <td>{{ row.item.report }}</td>
            <td>{{ format_date(row.item.created_at) }}</td>
            <td>
              <v-btn
                small
                depressed
                color="red"
                @click="reportAction(row.item.reportId, 'banned')"
              >
                Hapus
              </v-btn>
              <v-btn
                small
                outlined
                depressed
                color="red"
                @click="declineReport(row.item.reportId)"
              >
                HAPUS REPORT
              </v-btn>
            </td>
          </tr>
        </template></v-data-table
      >
    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import LoadingScreen from "../components/LoadingScreen.vue";

export default {
  name: "ReportTimeline",

  components: { LoadingScreen },

  data() {
    return {
      search: "",
      headers: [
        { text: "Report Id", value: "reportId" },
        { text: "Nama Pelapor", value: "user.fullName" },
        { text: "Konten", value: "reportData" },
        { text: "Laporan", value: "report" },
        { text: "Dibuat", value: "created_at" },
        { text: "Action", value: "" },
      ],
      datas: [],
      isLoading: true,
      baseWebURL: process.env.VUE_APP_WEB_URL,
    };
  },

  methods: {
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    declineReport(id) {
      let doConfirm = window.confirm(`Sure to decline this report?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .delete(
            `${process.env.VUE_APP_API_URL}/v2/admin/report/${id}/delete`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message || "Unauthorized");
            this.loading = false;
          });
      }
    },
    reportAction(id, action) {
      let doConfirm = window.confirm(`Sure to ${action} this?`);

      if (doConfirm) {
        let config = {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getToken}`,
          },
        };

        axios
          .create({ withCredentials: false })
          .get(
            `${process.env.VUE_APP_API_URL}/v2/admin/report/${id}/${action}`,
            config
          )
          .then((response) => {
            this.fetch();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            this.$toast.warning(error.response.data.message || "Unauthorized");
            this.loading = false;
          });
      }
    },
    fetch() {
      let config = {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      };
      axios
        .create({ withCredentials: false })
        .get(`${process.env.VUE_APP_API_URL}/v2/admin/report/feed`, config)
        .then((response) => {
          this.isLoading = false;
          this.datas = response.data.result;
        })
        .catch((error) => {
          this.$toast.warning(error.response.data.message || "Unauthorized");
          this.loading = false;
        });
    },
  },

  mounted() {
    this.fetch();
  },
};
</script>
